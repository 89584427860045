
//senior
import Block from '../views/blockUser'

// 路由懒加载
const getView = (name = '', path = '') => () => import(`@/views/${name}/${path}/components/main.vue`);

export default [
  {
    path: 'block',
    component: Block,
    name: 'block',
    meta: {
      title: '停用用户',
      key: '30',
    },
    children: [
      {
        path: '/admin/block',
        redirect: 'block',
      },
      {
        path: 'block',
        component: getView('blockUser', 'block'),
        name: 'blockUser',
        meta: {
          title: '停用用户',
          key: '30-1',
        }
      },
    ]
  },
]