import Vue from "vue";
import i18n from "@/locale"
import Cookies from "js-cookie";

const checkName = (rule, value, callback) => {
  if (!value) {
    return callback(new Error(i18n.t('all.Message47')));
  }
  if (value.includes(' ')) {
    return callback(new Error(i18n.t('all.Message48')))
  }
  callback()
};

const checkLoginPassword = (rule, value, callback) => {
  if (!value) {
    return callback(new Error(i18n.t('all.Message49')));
  }
  if (value.includes(' ')) {
    return callback(new Error(i18n.t('all.Message50')))
  }
  callback()
};

const checkPassword = (rule, value, callback) => {
  const reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,16}$/
  if (!reg.test(value)) {
    return callback(new Error(i18n.t('message.message5')))
  }
  if (!value) {
    return callback(new Error(i18n.t('all.Message49')));
  }
  if (value.includes(' ')) {
    return callback(new Error(i18n.t('all.Message50')))
  }
  if (value.length < 8 || value.length > 16) {
    return callback(new Error(i18n.t('message.message6')))
  }

  callback()
};

//管理员或者root密码可能会有特殊，为此专门写一个
const checkPasswordForAdmin = (rule, value, callback) => {
  if (!value) {
    return callback(new Error(i18n.t('all.Message49')));
  }
  if (value.includes(" ")) {
    return callback(new Error(i18n.t('all.Message50')));
  }
  if (value.length < 8 || value.length > 16) {
    return callback(new Error(i18n.t('message.message6')));
  }
  callback();
};

const checkUsername = (rule, value, callback) => {
  const reg = /^[A-Za-z0-9\u4e00-\u9fa5\u0020]+$/

  if (!value) {
    return callback(new Error(i18n.t('all.Message53')));
  }
  let lang = Cookies.get("lang");
  if(lang==='zh-CN'){
    if (!reg.test(value)) {
      return callback(new Error(i18n.t('all.Message54')))
    }
  }

  if (value.length > 20) {
    return callback(new Error(i18n.t('message.message9')))
  }
  callback()
};

const phonevalid = (rule, value, callback) => {
  console.log('value======',value)
  if (value === 'admin') return callback()
  let reg =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  if (!value) {
    return callback(new Error(i18n.t('all.Message56')));
  }
  if (!reg.test(Number(value))) {
    return callback(new Error(i18n.t('all.Message57')));
  }
  callback();
};

const checkCode = (rule, value, callback) => {
  if (!value) {
    return callback(new Error(i18n.t('all.Message58')));
  }
  if (value.includes(' ')) {
    return callback(new Error(i18n.t('all.Message59')))
  }
  if ((value.length !== 6)) {
    return callback(new Error(i18n.t('message.message14')))
  }
  callback()
};

const checkEmail = (rule, value, callback) => {
  let reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
  if (!value) {
    return callback(new Error(i18n.t('all.Message61')));
  }
  if (!reg.test(value)) {
    return callback(new Error(i18n.t('all.Message62')));
  }
  callback();
};

const checkAddress = (rule, value, callback) => {
  if (value.length > 50) {
    return callback(new Error(i18n.t('message.message17')))
  }
}

/*
*第二个参数表明是否静默校验
*/
const phoneNumberReg = function (num, quite) {
  let len = num.length;
  let reg =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  let Num = Number(num);

  if (len == 0) {
    !quite && Vue.prototype.$message.warning(i18n.t('all.Message56'));
    return false;
  }

  if (isNaN(Num)) {
    !quite && Vue.prototype.$message.warning(i18n.t('all.Message64'));
    return false;
  }

  let res = reg.test(Num);
  if (!res) {
    !quite && Vue.prototype.$message.warning(i18n.t('all.Message65'));
    return false;
  }

  return true;
}

function checkPosition(rule, value, callback) {
  let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,20}$/
  if (value == "") return callback()
  if (value.length > 20) {
    return callback(new Error(i18n.t('message.message20')))
  }
  if (!reg.test(value)) {
    callback(new Error(i18n.t('all.Message67')))
  }
  
  callback()
};

function checkStrictName(rule, value, callback) {
  let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,20}$/
  if (!reg.test(value)) {
    callback(new Error(i18n.t('all.Message67')))
  }
  callback()
};

export {
  checkName, checkPassword, phonevalid, checkCode, phoneNumberReg, checkUsername, checkLoginPassword, checkPosition, checkStrictName, checkPasswordForAdmin, checkEmail, checkAddress
}