/*
 * @Author: your name
 * @Date: 2021-09-17 16:28:35
 * @LastEditTime: 2021-11-29 11:34:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ihub\src\store\getters.js
 */

export const get_menuNavFlag = (state) => {
  return state.menuNavFlag;
};

export const get_rightHeaderFlag = (state) => {
  return state.rightHeaderFlag;
};

export const get_adminOrUser = (state) => {
  return state.adminOrUser;
};

export const get_routes = (state) => {
  return state.adminRoute;
};

export const get_edit = (state) => {
  return state.edit;
};

export const get_menuActive = (state) => {
  return state.menuActive;
};

export const get_token = (state) => {
  return state.token;
};

export const get_x_token = (state) => {
  return state.x_token;
};

export const get_blank = (state) => {
  return state.blank;
};

export const get_userinfo = (state) => {
  return state.userinfo;
};

export const get_meetingSet = (state) => {
  return state.meetingSet;
};
