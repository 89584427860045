
//senior
import Log from '../views/log'
import Software from '../views/software'
import apiSt from '../views/apist'
import i18n from '@/locale'

// 路由懒加载
const getView = (name = '', path = '') => () => import(`@/views/${name}/${path}/components/main.vue`);

export default [
  {
    path: 'apiSt',
    component: apiSt,
    name: 'apiSt',
    meta: {
      title: i18n.t('route.interface'),
      key: '__8',
      permission: [],
      icon: require('@/assets/router/management of meetings.png')
    },
    children: [
      {
        path: '/admin/api',
        redirect: 'apistatistics',
      },
      {
        path: '/apiSt',
        component: getView('apist', 'apistatistics'),
        name: 'apistatistics',
        meta: {
          title: i18n.t('route.interface'),
          key: '__8-1',
          permission: [],
          icon: require('@/assets/router/Meeting content.png')
        }
      },
    ]
  },
  //日志
  {
    path: 'log',
    component: Log,
    name: 'log',
    meta: {
      title: i18n.t('all.Message38'),
      key: '8',
      permission: ['admin', 'root'],
      icon: require('@/assets/router/management of meetings.png')
    },
    children: [
      {
        path: '/admin/log',
        redirect: 'logmanagement',
      },
      {
        path: 'logmanagement',
        component: getView('log', 'logmanagement'),
        name: 'logmanagement',
        meta: {
          title: i18n.t('all.Message38'),
          key: '8-1',
          permission: ['admin', 'root'],
          icon: require('@/assets/router/Meeting content.png')
        }
      },
    ]
  },
  //版本
  {
    path: 'software',
    component: Software,
    name: 'softwareAdvanced',
    meta: {
      title: i18n.t('route.advanced'),
      key: '_8',
      permission: ['root'],
      icon: require('@/assets/router/senior.png')
    },
    children: [
      {
        path: '/admin/software',
        redirect: 'update'
      },
      {
        path: 'update',
        component: getView('software', 'update'),
        name: 'update',
        meta: {
          title: i18n.t('route.versionManage'),
          key: '_8-2',
          permission: ['root'],
          icon: require('@/assets/router/set up.png')
        }
      },
      {
        path: 'softedit',
        component: getView('software', 'softwareedit'),
        name: 'softedit',
        meta: {
          title: i18n.t('route.modify'),
          key: '_8-4',
          notInNavMenu: true,
          permission: ['root'],
        }
      },
      {
        path: 'software',
        component: getView('software', 'software'),
        name: 'software',
        meta: {
          title: i18n.t('route.add'),
          key: '_8-3',
          notInNavMenu: true,
          permission: ['root'],
        }
      },
    ]
  },
]