/*
 * @Author: your name
 * @Date: 2021-09-17 16:28:35
 * @LastEditTime: 2021-11-29 11:27:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ihub\src\store\mutation-type.js
 */


export const SET_MENUNAV = 'SET_MENUNAV'
export const SET_RIGHTHEADER = 'SET_RIGHTHEADER'
export const SET_ADMINORUSER = 'SET_ADMINORUSER'
export const SET_ROUTES = 'SET_ROUTES'
export const SET_EDIT = 'SET_EDIT'
export const SET_MENUACTIVE = 'SET_MENUACTIVE'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_X_TOKEN = 'SET_X_TOKEN'
export const SET_BLANK = 'SET_BLANK'

export const SET_USERINFO = 'SET_USERINFO'
export const SET_MEETINGSET = 'SET_MEETINGSET'
export const ADD_ROUTES = 'ADD_ROUTES'



