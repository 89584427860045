
//resource
import Resource from '../views/meetingresource'
import PeopleManagement from '../views/peoplemanagement'
import i18n from '@/locale'

// 路由懒加载
const getView = (name = '', path = '') => () => import(`@/views/${name}/${path}/components/main.vue`);

export default [
  //会议文件和会议人员----在编辑页面
  {
    path: 'meetingresource',
    component: Resource,
    name: 'meetingResource',
    meta: {
      title: i18n.t('route.meetingMaterials'),
      key: '10',
      edit: true,
      permission: ['user', 'admin'],
      icon: require('@/assets/router/Meeting content.png'),
    },
    children: [
      // 默认路由-- - 会议资料
      {
        path: '/admin/meetingresource',
        redirect: 'votescore',
      },
      // {
      //   path: 'agenda',
      //   component: getView('meetingresource', 'agenda'),
      //   name: 'meetingAgenda',
      //   meta: {
      //     title: '会议议程',
      //     permission: ['user'],
      //     key: '10-1',
      //   },
      // },
      // {
      //   path: 'issue',
      //   component: getView('meetingresource', 'issue'),
      //   name: 'meetingIssue',
      //   meta: {
      //     title: '会议议题',
      //     key: '10-2',
      //     permission: ['user'],
      //   },
      // },
      // {
      //   path: 'resource',
      //   component: getView('meetingresource', 'resource'),
      //   name: 'resource',
      //   meta: {
      //     title: '会议云盘',
      //     key: '10-3',
      //     permission: ['user'],
      //   },
      // },
      {
        path: 'votescore',
        component: getView('meetingresource', 'votescore'),
        name: 'voteScore',
        meta: {
          title: i18n.t('route.voteScore'),
          key: '10-4',
          permission: ['user', 'admin'],
        },
      },
    ]
  },
  {
    path: 'peoplemanagement',
    component: PeopleManagement,
    name: 'meetingPeopleManagement',
    meta: {
      title: i18n.t('route.personalManage'),
      key: '11',
      permission: ['user', 'admin'],
      icon: require('@/assets/router/Personnel management.png'),
      edit: true
    },
    children: [
      {
        path: '/admin/peoplemanagement',
        redirect: 'peoplemanagement'
      },
      // {
      //   path: 'peoplemanagement',
      //   component: getView('peoplemanagement', 'peoplemanagement'),
      //   name: 'participants',
      //   meta: {
      //     title: '与会者列表',
      //     key: '11-1',
      //     permission: ['user', 'admin'],
      //   },
      // },
      {
        path: 'signin',
        component: getView('peoplemanagement', 'signin'),
        name: 'signIn',
        meta: {
          title: i18n.t('route.signManage'),
          key: '11-2',
          permission: ['user', 'admin'],
        },
      }
    ]
  }
]