
import Admin from '../views'
import Main from '../views/meetingset'  //由于是刚进去就要显示的页面，我把它取名为 main ，实际上对应的是name为 myMeeting 的页面
import Profile from '../views/profile'
import Recording from '../views/recording'
import i18n from '../locale'

// 路由懒加载
const getView = (name = '', path = '') => () => import(`@/views/${name}/${path}/components/main.vue`);

export default [
  {
    path: '/admin',
    redirect: 'meetingset',
  },
  {
    path: 'meetingset',
    component: Main,
    name: 'mettingGroup',
    meta: {
      title: i18n.t('all.Message43'),
      key: '1',
      permission: ['user'],
      icon: require('@/assets/router/meeting(1).png'),

    },
    children: [
      //默认路由
      {
        path: '/admin/meetingset',
        redirect: 'main'
      },
      {
        path: 'main',
        component: getView('meetingset', 'main'),
        name: 'myMeeting', //我的会议
        meta: {
          title: i18n.t('all.Message8'),
          // key: '1-1',
          key: '7-11',
          permission: ['user'],
        }
      },
      {
        path: 'meetingroom',
        component: getView('meetingset', 'meetingroom'),
        name: 'meetingRoomCreate', //创建会议
        meta: {
          title: i18n.t('all.Message9'),
          // key: '1-4',
          key: '7-14',
          permission: ['user'],
        }
      },
      {
        path: 'meetinghistory',
        component: getView('meetingset', 'meetinghistory'),
        name: 'meetingHistory', //会议历史
        meta: {
          title: i18n.t('all.Message12'),
          key: '1-3',
          permission: ['user'],
        }
      },

      {
        path: 'meetingedit',
        component: getView('meetingset', 'meetingedit'),
        name: 'meetingRoomEdit', //会议编辑
        meta: {
          title: i18n.t('all.Message10'),
          key: '1-5',
          notInNavMenu: true,
          permission: ['user'],
        }
      },
    ]
  },
  {
    path: 'profile',
    component: Profile,
    name: 'profileGroup',
    meta: {
      title: i18n.t('all.Message22'),
      key: '2',
      permission: ['user'],
      notInNavMenu: true,
    },
    children: [
      //默认路由---个人信息功能
      {
        path: '/admin/profile',
        redirect: 'profile'
      },
      {
        path: 'profile',
        component: getView('profile', 'profile'),
        name: 'profile', //个人信息
        meta: {
          title: i18n.t('all.Message22'),
          key: '2-1',
          permission: ['user'],
          icon: require('@/assets/router/Personal information.png'),
        }
      },
      {
        path: 'cancelacount',
        component: getView('profile', 'cancelAccount'),
        name: 'cancelAcount',
        meta: {
          title: i18n.t('all.Message23'),
          key: '2-2',
          permission: ['user'],
          notInNavMenu: true,
        }
      },
      {
        path: 'quit',
        component: getView('profile', 'quit'),
        name: 'quit', //
        meta: {
          title: i18n.t('all.Message24'),
          key: '2-3',
          permission: ['user'],
          notInNavMenu: true,
        }
      },
    ]
  },
  {
    path: 'recording',
    component: Recording,
    name: 'recordingGroup',
    meta: {
      title: i18n.t('all.Message44'),
      key: '4',
      permission: ['user'],
    },
    children: [
      //默认路由---录制功能
      {
        path: '/admin/recording',
        redirect: 'recording'
      },
      {
        path: 'recording',
        component: getView('recording', 'recording'),
        name: 'myRecording', //我的录制
        meta: {
          title: i18n.t('all.Message44'),
          key: '4-1',
          permission: ['user'],
          icon: require('@/assets/router/Conference recording.png'),
        }
      }
    ]
  },
]


export const shimRouter = {
  path: 'profile',
  component: Profile,
  name: 'profileGroup',
  meta: {
    title: i18n.t('all.Message22'),
    key: '2',
    permission: ['user'],
    notInNavMenu: true,
  },
  children: [
    //默认路由---个人信息功能
    {
      path: '/admin/profile',
      redirect: 'profile'
    },
    {
      path: 'profile',
      component: getView('profile', 'profile'),
      name: 'profile', //个人信息
      meta: {
        title: i18n.t('all.Message22'),
        key: '2-1',
        permission: ['user'],
        icon: require('@/assets/router/Personal information.png'),
      }
    },
    {
      path: 'cancelacount',
      component: getView('profile', 'cancelAccount'),
      name: 'cancelAcount',
      meta: {
        title: i18n.t('all.Message23'),
        key: '2-2',
        permission: ['user'],
        notInNavMenu: true,
      }
    },
    {
      path: 'quit',
      component: getView('profile', 'quit'),
      name: 'quit', //
      meta: {
        title: i18n.t('all.Message24'),
        key: '2-3',
        permission: ['user'],
        notInNavMenu: true,
      }
    },
  ]
}