import Enterprise from '../views/enterprise'   //企业管理
import MeetingRoom from '../views/meetingroom' //会议室管理
import Charts from '../views/charts'       //首页数据
import Profile from '../views/profile'
import Log from '../views/log'
import Software from '../views/software'
import User from '../views/users'

import store from '@/store'
import i18n from '@/locale'

// 路由懒加载
const getView = (name = '', path = '') => () => import(`@/views/${name}/${path}/components/main.vue`)


export default [

  {
    path: '/admin',
    redirect: 'charts',
  },

  //首页
  {
    path: 'charts',
    component: Charts,
    name: 'chartsGroup',
    meta: {
      title: i18n.t('all.Message6'),
      key: '18',
      permission: ['admin'],
      icon: require('@/assets/router/business management.png'),
    },
    children: [
      {
        path: '/admin/charts',
        redirect: 'charts'
      },
      {
        path: 'charts',
        component: getView('charts', 'charts'),
        name: 'dataMain', //首页
        meta: {
          title: i18n.t('all.Message6'),
          key: '18-1',
          permission: ['admin'],
          icon: require('@/assets/router/business management.png'),
        }
      },
    ]
  },
  //会议管理
  {
    path: 'meetingmanagement',
    component: MeetingRoom,
    name: 'meetingroomManagementGroup',
    meta: {
      title: i18n.t('all.Message7'),
      key: '7',
      permission: ['admin'],
      icon: require('@/assets/router/management of meetings.png')
    },
    children: [
      {
        path: '/admin/meetingmanagement',
        redirect: 'now',
      },
      {
        path: 'meetingmanagement',
        component: getView('meetingset', 'main'),
        name: 'myMeeting', //我的会议
        meta: {
          title: i18n.t('all.Message8'),
          key: '7-11',
          permission: ['admin'],
        }
      },
      {
        path: 'meetingroom',
        component: getView('meetingset', 'meetingroom'),
        name: 'meetingRoomCreate', //创建会议
        meta: {
          title: i18n.t('all.Message9'),
          key: '7-14',
          permission: ['admin'],
        }
      },
      {
        path: 'meetingedit',
        component: getView('meetingset', 'meetingedit'),
        name: 'meetingRoomEdit', //会议编辑
        meta: {
          title: i18n.t('all.Message10'),
          key: '1-5',
          notInNavMenu: true,
          permission: ['admin'],
        }
      },
      {
        path: 'now',
        component: getView('meetingmanagement', 'now'),
        name: 'meetingOngoing', //正在召开
        meta: {
          title: i18n.t('all.Message11'),
          key: '7-1',
          permission: ['admin'],
        }
      },
      {
        path: 'history',
        component: getView('meetingmanagement', 'history'),
        name: 'HistoricMeeting', //历史会议
        meta: {
          title: i18n.t('all.Message12'),
          key: '7-3',
          permission: ['admin'],
        }
      },
      {
        path: 'note',
        component: getView('meetingmanagement', 'note'),
        name: 'meetingNote', //会议妙记
        meta: {
          title: i18n.t('all.Message13'),
          key: '7-4',
          permission: ['admin'],
        }
      },
      {
        path: 'setting',
        component: getView('meetingmanagement', 'setting'),
        name: 'meetingManagementSetting', //会议设置
        meta: {
          title: i18n.t('all.Message14'),
          key: '7-5',
          permission: ['admin'],
        }
      },
    ]
  },
  //单位管理
  {
    path: 'enterprise',
    component: Enterprise,
    name: 'enterpriseGroup',
    meta: {
      title: i18n.t('all.Message15'),
      key: '5',
      permission: ['admin'],
      icon: require('@/assets/router/business management.png'),
    },
    children: [
      {
        path: '/admin/enterprise',
        redirect: 'org',
      },
      {
        path: 'org',
        component: getView('enterprise', 'org'),
        name: 'enterpriseOrg', //组织结构
        meta: {
          title: i18n.t('all.Message16'),
          key: '5-2',
          permission: ['admin'],
        }
      },
      {
        path: 'user',
        component: getView('enterprise', 'user'),
        name: 'enterpriseAbout', //单位信息
        meta: {
          title: i18n.t('all.Message17'),
          key: '5-3',
          permission: ['admin'],
        }
      },
      {
        path: 'disband',
        component: getView('enterprise', 'disband'),
        name: 'enterpriseDisband', //单位信息
        meta: {
          title: i18n.t('all.Message18'),
          key: '5-4',
          permission: ['admin'],
          notInNavMenu: true,
        }
      }
    ]
  },
  //会议室管理
  {
    path: 'meetingroom',
    component: MeetingRoom,
    name: 'meetingroomGroup',
    meta: {
      title: i18n.t('all.Message19'),
      key: '6',
      permission: ['admin'],
      icon: require('@/assets/router/Conference room management.png'),
      onemore: true
    },
    children: [
      {
        path: '/admin/meetingroom',
        redirect: 'ihub',
      },
      {
        path: 'ihub',
        component: getView('meetingroom', 'ihub'),
        name: 'meetingRoomManagement', //iHUB一体机
        meta: {
          title: i18n.t('all.Message844'),
          key: '6-1',
          permission: ['admin'],
          icon: require('@/assets/router/Conference room management.png'),
          onemore: true
        }
      },
    ]
  },
  //用户
  {
    path: 'users',
    component: User,
    name: 'users',
    meta: {
      title: i18n.t('all.Message21'),
      key: '20',
      permission: ['admin'],
      icon: require('@/assets/router/management of meetings.png')
    },
    children: [
      {
        path: '/admin/users',
        redirect: 'logmanagement',
      },
      {
        path: 'usermanagement',
        component: getView('users', 'about'),
        name: 'userManagement',
        meta: {
          title: i18n.t('all.Message21'),
          key: '20-1',
          permission: ['admin'],
          icon: require('@/assets/router/Personnel management.png')
        }
      },
    ]
  },
  /* 个人信息 */
  {
    path: 'profile',
    component: Profile,
    name: 'profileGroup',
    meta: {
      title: i18n.t('all.Message22'),
      key: '2',
      permission: ['user'],
      notInNavMenu: true,
    },
    children: [
      //默认路由---个人信息功能
      {
        path: '/admin/profile',
        redirect: 'profile'
      },
      {
        path: 'profile',
        component: getView('profile', 'profile'),
        name: 'profile', //个人信息
        meta: {
          title: i18n.t('all.Message22'),
          key: '2-1',
          permission: ['user'],
          icon: require('@/assets/router/Personal information.png'),
        }
      },
      {
        path: 'cancelacount',
        component: getView('profile', 'cancelAccount'),
        name: 'cancelAcount',
        meta: {
          title: i18n.t('all.Message23'),
          key: '2-2',
          permission: ['user'],
          notInNavMenu: true,
        }
      },
      {
        path: 'quit',
        component: getView('profile', 'quit'),
        name: 'quit', //
        meta: {
          title: i18n.t('all.Message24'),
          key: '2-3',
          permission: ['user'],
          notInNavMenu: true,
        }
      },
    ]
  },

]

