
// import { adminRoute } from '@/router/index.js'
const state = {
  menuNavFlag: false,    //侧边栏是否收起
  adminOrUser: '',       //权限，用户还是管理员
  adminRoute: true,      //是否展示管理员路由
  edit: false,           //展示编辑路由还是常规路由
  menuActive: '',        //当前路由
  token: '',
  x_token: '',
  blank: '',             //是否新开页面
  rightHeaderFlag:true,  //个人信息是否显示
  userinfo: null,
  meetingSet: {
    time_zone: '北京',    //时区
    is_secret: false,     //是否加密
    is_cipher: false,     //是否保密
    remain_time: 0        //留存时间
  }
};

export default state;