import * as types from './mutation-type.js';
import router from '@/router'

import userRouter from '@/router/userRouter'
import adminRouter from '@/router/adminRoute'
import seniorRouter from '@/router/seniorRoute'
import freeRouter from '@/router/freeUserRoute'
import blockRouter from '@/router/blockedRoute'

export default {
  [types.SET_MENUNAV](state, info) {
    state.menuNavFlag = info;
  },
  [types.SET_RIGHTHEADER](state, info) {
    state.rightHeaderFlag = info;
  },
  [types.SET_ADMINORUSER](state, info) {
    localStorage.setItem('adminOrUser', info)
    state.adminOrUser = info
  },
  [types.SET_ROUTES](state, info) {
    state.adminRoute = info
  },
  [types.SET_EDIT](state, info) {
    state.edit = info
  },
  [types.SET_MENUACTIVE](state, info) {
    state.menuActive = info
  },
  [types.SET_TOKEN](state, info) {
    state.token = info
  },
  [types.SET_X_TOKEN](state, info) {
    state.x_token = info
  },
  [types.SET_BLANK](state, info) {
    state.blank = info
  },
  [types.SET_USERINFO](state, info) {
    state.userinfo = info
  },
  [types.SET_MEETINGSET](state, info) {
    state.meetingSet = info
  },

  [types.ADD_ROUTES](state, payload) {
    //获取缓存路由
    let routeName = sessionStorage.getItem('route_name')
    if (routeName == 'null') routeName = ''

    //payload可能是个对象，包含info和jump，也可以是个info字符串
    let info = '',
      jump = ''
    if (typeof payload == 'string') {
      info = payload
    } else if (payload && typeof payload == 'object') {
      info = payload.info
      jump = payload.jump
    }

    //====对外分享的网页在登录情况下不根据local里面存的路由名跳转=====
    let notJumpsArray = ['Invite']
    //===========================================================

    //====跳转补丁================================================
    for (let i of notJumpsArray) {
      if (location.href.includes(i.toLocaleLowerCase())) routeName = i
      //如果jump存在，则需要清空路由缓存
      if (jump) {
        sessionStorage.setItem('route_name', '')
        routeName = ''
      }
    }
    // console.log('info======',info)
    //===========================================================
    if (!info) { //没有手动传入info的时候
      let userinfo = JSON.parse(localStorage.getItem('userinfo'))
      if (!userinfo) return //如果此时没有userinfo存入，什么也不做
      //改写info  info = state.adminOrUser
      //有userinfo信息，取出用户身份
      let userType = userinfo.userType
      let tenantId = userinfo.tenantId
      if (state.adminOrUser == 'audit') info = 'audit'
      else if (state.adminOrUser == 'confuse') info = 'confuse'
      // update:2023-11-02   tenantId改为判断 sysOrg
      // else if (tenantId == -1) info = 'free'
      else if (!userinfo.sysOrg) info = 'free'
      else if (userType == 1) info = 'admin'
      else if (userType == 0) info = 'user'
      else if (userType == 999) info = 'root'
    }
    /*当时登录页跳转过来时，会携带info*/
    // 正在申请的用户  
    if (info == 'audit') {
      freeRouter.forEach(i => router.addRoute('IHUBadmin', i))
      userRouter.forEach(i => router.addRoute('IHUBadmin', i))
      setTimeout(() => router.replace({
        name: routeName || "audit"
      }), 0);
      // 游离  申请被拒绝的用户
    } else if (info == 'confuse') {
      freeRouter.forEach(i => router.addRoute('IHUBadmin', i))
      userRouter.forEach(i => router.addRoute('IHUBadmin', i))
      setTimeout(() => router.replace({
        name: routeName || "confuse"
      }), 0);
      // 没有组织 没有申请的用户
    } else if (info == 'free') {

      freeRouter.forEach(i => router.addRoute('IHUBadmin', i))
      userRouter.forEach(i => router.addRoute('IHUBadmin', i))

      setTimeout(() => {
        router.replace({
          name: routeName || "freeUser"
        })
        console.log(routeName, '======routeName')
      }, 0);
      // 游离拒绝审核
    } else if (info == 'block') {
      blockRouter.forEach(i => router.addRoute('IHUBadmin', i))
      userRouter.forEach(i => router.addRoute('IHUBadmin', i))
      setTimeout(() => router.replace({
        name: routeName || 'blockUser'
      }), 0);
      // 审核通过
    } else if (info == 'user') {
      userRouter.forEach(i => router.addRoute('IHUBadmin', i))
      setTimeout(() => router.replace({
        name: routeName || "myMeeting"
      }), 0);
      // 管理员
    } else if (info == 'admin') {
      adminRouter.forEach(i => router.addRoute('IHUBadmin', i))
      freeRouter.forEach(i => router.addRoute('IHUBadmin', i))
      seniorRouter.forEach(i => router.addRoute('IHUBadmin', i))
      setTimeout(() => router.replace({
        name: routeName || 'dataMain'
      }), 0);
      // 超管
    } else if (info == 'root') {
      seniorRouter.forEach(i => router.addRoute('IHUBadmin', i))
      setTimeout(() => router.replace({
        name: routeName || 'update'
      }), 0);
    }
    /**添加404路由 */
    router.addRoute({
      path: '*',
      redirect: '/404'
    })
  },
};