//free
import Free from "../views/free";
import i18n from "@/locale";

// 路由懒加载
const getView = (name = "", path = "") => () =>
  import(`@/views/${name}/${path}/components/main.vue`);

export default [
  {
    path: "free",
    component: Free,
    name: "free",
    meta: {
      title: i18n.t("route.looseUser"),
      key: "20",
    },
    children: [
      {
        path: "/admin/free",
        redirect: "free",
      },
      {
        path: "free",
        component: getView("free", "free"),
        name: "freeUser",
        meta: {
          title: i18n.t("route.looseUser"),
          key: "20-1",
        },
      },
      {
        path: "create",
        component: getView("free", "create"),
        name: "createCompany",
        meta: {
          title: i18n.t("route.createUnit"),
          key: "20-2",
        },
      },
      {
        path: "createsuccess",
        component: getView("free", "createSuccess"),
        name: "createSuccess",
        meta: {
          title: i18n.t("route.createSucess"),
          key: "20-3",
        },
      },
      {
        path: "audit",
        component: getView("free", "audit"),
        name: "audit",
        meta: {
          title: i18n.t("route.waitReview"),
          key: "20-4",
        },
      },
      {
        path: "confuse",
        component: getView("free", "confuse"),
        name: "confuse",
        meta: {
          title: i18n.t("route.reviewRejected"),
          key: "20-5",
        },
      },
    ],
  },
];
