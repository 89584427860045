import Vue from "vue";

import VueRouter from "vue-router";
import store from "@/store";

//导入路由
import editRoute from "./editRoute";
import {
  shimRouter
} from "./userRouter";

//admin
import Admin from "../views";

//login
import Login from "@/_login";

//register
import Register from "@/_register";

//404
import _404 from "../_404";

//forgot password
import Forgot from "@/_forgot";

//fail
import RegisterFail from "@/_registerFail";

//videoviewer
import videoViewer from "@/_videoViewer";

//adminbindtel
import AdminTel from "@/_adminTel";

//invite
import Invite from "@/_invite";

import i18n from "@/locale";

Vue.use(VueRouter);

/*
  重复路由报错处理
  即路由push return是个promise，但是未捕获错误，捕获一下就好了
  使用vueRouter 3.x 版本中的导航故障检测，这样方便扩展一些别的检测，详见：
  https://router.vuejs.org/zh/guide/advanced/navigation-failures.html#%E6%A3%80%E6%B5%8B%E5%AF%BC%E8%88%AA%E6%95%85%E9%9A%9C
*/

const {
  isNavigationFailure,
  NavigationFailureType
} = VueRouter;
const routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return routerPush.call(this, location, onResolve, onReject);
  return routerPush.call(this, location).catch((failure) => {
    //重复路由时
    if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
      // 向用户显示一个小通知
      console.log("Right Here,Sir");
    }
  });
};

let routes = [{
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: Login,
    name: "login",
  },
  {
    path: "/register",
    component: Register,
    name: "register",
  },
  {
    path: "/forgot",
    component: Forgot,
    name: "forgot",
  },
  {
    path: "/fail",
    component: RegisterFail,
    name: "fail",
  },
  {
    path: "/404",
    component: _404,
    name: "404",
  },
  {
    path: "/videoviwer",
    component: videoViewer,
    name: "videoViewer",
  },
  {
    path: "/bind",
    component: AdminTel,
    name: "adminTel",
  },
  {
    path: "/invite",
    component: Invite,
    name: "Invite",
  },
  {
    path: "/admin",
    component: Admin,
    name: "IHUBadmin",
    meta: {
      title: i18n.t("global.title"),
    },
    children: [],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

/*
 **如果是管理员权限，则添加路由 --- 之所以不直接concat，是为了让这部分路由在options中隐藏
 **addRoute 不会关联修改 options.routes 详见 https://github.com/vuejs/vue-router/issues/1859
 **/
editRoute.forEach((i) => router.addRoute("IHUBadmin", i));

// 全局路由守卫,用于控制侧边导航栏 ---
router.beforeEach((to, from, next) => {
  //没有token时前往非登录页
  let withoutPermissionArr = [
    "login",
    "register",
    "forgot",
    "fail",
    "404",
    "invite",
  ];
  let withoutPermission = withoutPermissionArr.some((i) => to.path.includes(i));
  let token = localStorage.getItem("ihub_token");

  //没有token，不放行，弹回登录页
  if (!withoutPermission && !token) {
    Vue.prototype.$message.error(i18n.t("axios.pleaseLogin"));
    return next("/login");
  }

  //侧边导航栏控制
  let arr = ["/admin/meetingresource", "/admin/peoplemanagement"];
  let fromFlag = arr.some((i) => from.fullPath.includes(i)); //是否from  arr中的某一个路由
  let toFlag = arr.some((i) => to.fullPath.includes(i)); //是否前往  arr中的某一个路由

  //如果来自根路由，且前往一个非会议管理的路由，需要addRoute，用于处理用户直接复制路径在新标签页打开 （会议管理路由无需区分权限去添加，所以只需要处理非会议管理的情况）
  if (from.fullPath === "/" && !toFlag) {
    store.commit("ADD_ROUTES", localStorage.getItem("adminOrUser"));
  }
  if (to.fullPath == "/admin/free/create") {
    store.commit("SET_RIGHTHEADER", false);
  }
  if (from.fullPath == "/admin/free/create") {
    store.commit("SET_RIGHTHEADER", true);
  }

  if (fromFlag && toFlag) store.commit("SET_EDIT", true);
  if (fromFlag && !toFlag) store.commit("SET_EDIT", false);
  if (!fromFlag && toFlag) store.commit("SET_EDIT", true);
  if (!fromFlag && !toFlag) store.commit("SET_EDIT", false);

  //找不到白屏原因，打个补丁
  if (to.name == "profile") {
    if (router.getRoutes().some((r) => r.name == "profile")) return next();
    router.addRoute(shimRouter);
    return next(...to);
  }
  // 记录route_name 当前路由  
  sessionStorage.setItem('route_name', to.name)
  if (to.name === 'register') {
    sessionStorage.setItem('route_name', "")
  }
  next();
});

export default router;